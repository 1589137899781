import mixpanel from "mixpanel-browser";

const isCypress = typeof window.Cypress !== "undefined";
const isDev = process.env.NODE_ENV !== "production";

if (!isCypress) {
  mixpanel.init("5bcebe52ff1334a77e826755c8902ae6");
}

type EventName =
  | "PAGE_VIEWED"
  | "PAYMENT_TYPE_SELECTED"
  | "PAYMENT_METHOD_TOGGLED"
  | "LOCATION_SELECTED"
  | "CURRENCY_SELECTED"
  | "CTA_CLICKED";

interface Actions {
  track: (name: EventName, props?: Record<string, any>) => void;
}

function useEventTracking(): Actions {
  return {
    track: (...args) => {
      if (isDev || isCypress) return console.log(...args);
      return mixpanel.track(...args);
    },
  };
}

export default useEventTracking;
