import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import {
  getLocaleFromAppLanguage,
  AppLanguage,
  intlData,
  getAppMeta,
} from "../../i18n";
import imageUrl from "./og-image-mollie.png";
import useAppLanguage from "../../hooks/useAppLanguage";

function GlobalMetaTags() {
  const { pathname } = useLocation();
  const appLanguage = useAppLanguage();
  const isHomepage = pathname === `/${appLanguage}`;
  const { title, description } = getAppMeta(appLanguage as AppLanguage);
  const url = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  return (
    <Helmet>
      <html lang={getLocaleFromAppLanguage(appLanguage)} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        http-equiv="content-language"
        content={getLocaleFromAppLanguage(
          appLanguage as AppLanguage
        ).toLocaleLowerCase()}
      />
      <link rel="canonical" href={pathname} />

      {isHomepage ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, follow" />
      )}

      {intlData.map((option) => (
        <link
          key={option.locale}
          rel="alternate"
          href={`${url}/${option.appLanguage}`}
          hrefLang={option.locale.toLocaleLowerCase()}
        />
      ))}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Mollie" />

      <meta
        name="twitter:site"
        content={appLanguage === "nl" ? "@MollieNL" : "@MolliePayments"}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      <script
        defer
        src="https://cdn.website.mollie.com/libs/cookie-consent-manager.js"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdn.website.mollie.com/libs/cookie-consent-manager.css"
      />
      <script>
        {`
          if (!window.Cypress) {
            window.initCCM = function () {
              CCM.init("gFfX6AizC", { language: "${appLanguage}" });
            };
          };
        `}
      </script>
    </Helmet>
  );
}

export default GlobalMetaTags;
