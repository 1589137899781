import React from "react";
import { useLocation } from "react-router";
import useEventTracking from "../../hooks/useEventTracking";
import usePrevious from "../../hooks/usePrevious";

function TrackPageView() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const { track } = useEventTracking();

  React.useEffect(() => {
    if (pathname !== prevPathname) {
      track("PAGE_VIEWED", { pathname });
    }
  }, [track, pathname, prevPathname]);

  return null;
}

export default TrackPageView;
